import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/00_header/header';
import Home from './components/01_home/home';
import Experience from './components/02_experience/experience';
import Education from './components/03_education/education';
import Research from './components/04_research/research';
import Footer from './components/06_footer/footer';
import DataPolicy from './pages/datapolicy';
import Imprint from './pages/imprint';
import { ThemeProvider, createTheme } from '@mui/material/styles';


const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={
              <>
                <Header />
                <div id="home">
                  <Home />
                </div>
                <div id="education">
                  <Education />
                </div>
                <div id="research">
                  <Research />
                </div>
                <div id="experience">
                  <Experience />
                </div>
                <Footer />
              </>
            } />
            <Route path='/imprint' element={<Imprint />} />
            <Route path='/data-policy' element={<DataPolicy />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
