import React from 'react';
import Section from '../common/section'

import microsoftLogo from "../../images/logos/microsoft.jpg";
import snowflakeLogo from "../../images/logos/snowflake.jpg";
import munichReLogo from "../../images/logos/munichre.jpg";
import netlightLogo from "../../images/logos/netlight.jpg";
import deloitteLogo from "../../images/logos/deloitte.jpg";
import amazoneLogo from "../../images/logos/amazone.jpg";
import ororatechLogo from "../../images/logos/ororatech.jpg";
import faktor30Logo from "../../images/logos/faktor30.jpg";


const experiences = [
      {
      name: "Microsoft",
      type: "Internship",
      title: "CSA & Software Engineering",
      period: "02/2024 - 07/2024",
      location: "Munich, Germany",
      description: "I contributed to the Microsoft ISV team. The ISV team develops frameworks for Azure Marketplace third-party development. I implemented a third-party VS Code extension for IaaC development. Furthermore, I contributed to the Cloud Security team. The Cloud Security team advises mid to large-scale companies on cloud security architecture. I implemented a chat bot that accelerates workflows for security CSA's.",
      imageUrl: microsoftLogo
    },
    {
      name: "Snowflake",
      type: "Internship",
      title: "Software Engineer",
      period: "11/2023 - 01/2024",
      location: "Warsaw, Poland",
      description: "I contributed to the Database Connectors team. The DB Connectors team provides native integration of database systems into the Snowflake. I implemented a Connector for an SQL database type that supports snapshots and continuous replications into the Snowflake.",
      imageUrl: snowflakeLogo
    },
    {
      name: "Munich Re",
      type: "Internship",
      title: "Software Engineer",
      period: "08/2023 - 10/2023",
      location: "Munich, Germany",
      description: "I contributed to the Cloud Computing team. The Cloud Computing team develops internal cloud infrastructure such as a Python library for data pipelines. I refactored the library to improve its performance by utilizing an asynchronous approach.",
      imageUrl: munichReLogo
    },
    {
      name: "Netlight",
      type: "Internship",
      title: "Software Engineer",
      period: "10/2022 - 02/2023",
      location: "Munich, Germany",
      description: "I contributed to the learning and development team as an TU Munich iPraktikum student. The learning and development team develops personal growth solutions for Netlight employees. I contributed to the development of a Swift App that offers video courses and learning progress sharing by an integrated social media.",
      imageUrl: netlightLogo 
    },
    {
      name: "Deloitte",
      type: "Internship",
      title: "Software Engineer",
      period: "11/2022 - 01/2023",
      location: "Munich, Germany",
      description: "I contributed to the Data Analytics team. The Data Analytics team develops solutions for big data use cases in audit workflows. I implemented automation tools for audit workflows and data type conversions.",
      imageUrl: deloitteLogo 
    },
    {
      name: "Amazone",
      type: "Internship",
      title: "Software Engineer",
      period: "03/2022 - 04/2022",
      location: "Osnabrueck, Germany",
      description: "I contributed to the Research and Development team. The Research and Development team creates cutting-edge agricultural solutions. I developed a machine learning model for the optimization of fertilizer machine precision. Furthermore, I developed an application that tracks and analyzes GPS coordinates.",
      imageUrl: amazoneLogo
    },
    {
      name: "Orora Technologies",
      type: "Internship",
      title: "Innovation Consultant",
      period: "10/2021 - 03/2022",
      location: "Munich, Germany",
      description: "I contributed to the Research and Development team within the Manage and More scholarship program. The scholarship encompasses a consulting project that teaches scholars design thinking. I investigated the attractiveness of real-time satellite data for the re-insurance market.",
      imageUrl: ororatechLogo
    },
    {
      name: "Faktor30",
      type: "Part-Time & Full-Time",
      title: "Co-Founder",
      period: "03/2019 - 11/2021",
      location: "Reinbek, Germany",
      description: "I co-founded a B2B Software Engineering company. The company sold software engineering services to customers, including end-to-end software solutions for a food delivery platform. Major customers were the Hamburger Sparkasse and Henssler Food Concepts AG. I led the employees, developed the software, shipped the products.",
      imageUrl: faktor30Logo
    }
];

const Experience = () => {
  return <Section sectionTitle="Work Experience" data={experiences} />;
};

export default Experience;
