import React, { useState } from 'react';
import { useTheme, useMediaQuery, Grid, Typography, Box, Link, Button } from '@mui/material';

const specialLinks = {
  "Manage and More": "https://www.unternehmertum.de/manage-and-more.html",
  "Clouditor": "https://github.com/clouditor/clouditor",
  "Teaching iOS": "https://ase.in.tum.de/lehrstuhl_1/teaching/1203-teaching-ios-ws22-23",
  "iPraktikum": "https://ase.in.tum.de/lehrstuhl_1/projects/all-projects.html#iOS",
  "end-to-end software solution": "https://www.abendblatt.de/region/stormarn/article230111786/Reinbeker-Lieferdienst-erfolgreich-Best-Bite-expandiert.html",
  "Swift App": "https://ase.cit.tum.de/projects/ipraktikum/22w/netlight/",
  "DB Connectors": "https://other-docs.snowflake.com/en/connectors",
};

const Section = ({ sectionTitle, data }) => {
  // Initialize expanded state as an empty object
  const [expanded, setExpanded] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleExpand = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  const renderDescriptionWithLink = (description, index) => {
    const isExpanded = expanded[index];
    const shouldShorten = description.length > 120 && !isExpanded;
    const displayDescription = shouldShorten ? `${description.substring(0, 117)}...` : description;
  
    return (
      <>
        {Object.keys(specialLinks).reduce((acc, key) => {
          if (displayDescription.includes(key)) {
            const parts = displayDescription.split(key);
            return (
              <>
                {parts[0]}
                <Link href={specialLinks[key]} target="_blank" rel="noopener" style={{ textDecoration: 'none', marginRight: '2px' }}>
                  {key}
                </Link>
                {parts[1]}
              </>
            );
          }
          return acc;
        }, displayDescription)}
        {description.length > 120 && (
          <span style={{ marginLeft: '3px' }}>
            <Button
              onClick={() => toggleExpand(index)}
              style={{ padding: '0px', minWidth: 'auto' }}
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            </Button>
          </span>
        )}
      </>
    );
  };

  const handleOpenPdf = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <Typography variant="h4" style={{ textAlign: 'left', padding: '20px' }}>{sectionTitle}</Typography>
      <Grid container spacing={1} direction={'column'} style={{ padding: '20px', width: isMobile ? '100%' : '70%' }}>
        {data.map((item, index) => (
          <Grid item direction={'column'} key={index} style={{ marginBottom: '10px' }}>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', marginRight: '20px'}}>
                <Typography variant="subtitle1" fontWeight={'bold'} style={{ textAlign: 'left', marginRight: '5px'}}>{(item.name ? `${item.name} - ` : '') + (item.title ? `${item.title}` : '') + (item.type ? ` - ${item.type}` : '')}</Typography>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', marginRight: '20px' }}>
                <Typography  variant="body1" fontWeight={'light'} style={{ textAlign: 'left', marginRight: '5px' }}>{item.location ? `${item.location},` : ''}</Typography>
                <Typography variant="body1" fontWeight={'light'} style={{ textAlign: 'left' }}>{item.period ? `${item.period}` : ''}</Typography>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', marginRight: '20px' }}>
                <Typography variant="body1" fontWeight={'medium'} style={{ textAlign: 'left'}}>{renderDescriptionWithLink(item.description, index)}</Typography>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', marginRight: '20px' }}>
                {item.pdfLink && (
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenPdf(item.pdfLink)}
                      style={{ marginTop: '5px', padding: '3px' }}
                    >
                      View Paper
                    </Button>
                  )}
              </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Section;
