import React from 'react';
import Section from '../common/section'

import aisecLogo from "../../images/logos/aisec.jpg";
import tumLogo from "../../images/logos/tum.jpg";
import mirmiLogo from "../../images/logos/mirmi.jpg";
import bachelorThesisDoc from "../../documents/bachelor_thesis.pdf";
import mirmiDoc from "../../documents/optimal_control_legged_robots.pdf";

const experiences = [
  {
    name: "TUM",
    lab: "Chair of IT Security - TU Munich",
    title: "Runtime Attestation",
    period: "05/2023 - 09/2023",
    location: "Munich, Germany",
    description: "I researched the adaptation of Runtime Attestation Driven Development (RADD) to enhance security in software systems, focusing on its application in different operating systems and through the manipulation of data structures. Attestation is a trust mechanism that verifies the integrity of information provided by a system and is used to detect security attacks. I devised a programming model that stores all data structures in the call stack according to the required RADD structure.",
    imageUrl: tumLogo,
    pdfLink: bachelorThesisDoc
  },
  {
    name: "AISEC",
    lab: "Fraunhofer Institute for Applied and Integrated Security",
    title: "Cloud System Security",
    period: "04/2023 - 09/2023",
    location: "Munich, Germany",
    description: "I contributed my research to the Cloud Security team that is supervised by Prof. Dr. Claudia Eckert. The Cloud Security team develops Clouditor, a tool that supports continuous cloud security. I researched and implemented metrics that evaluate the security of cloud systems.",
    imageUrl: aisecLogo
  },
  {
    name: "MIRMI",
    lab: "Munich Institute of Robotics and Machine Intelligence",
    title: "Optimal Control and Reinforcement Learning",
    period: "07/2022 - 09/2022",
    location: "Munich, Germany",
    description: "I researched optimal control strategies for legged robots, focusing on enhancing their stability and agility to match their biological counterparts. The research focuses on the development of real-time control solutions that address the computational complexity of advanced locomotion of legged robots.",
    imageUrl: mirmiLogo,
    pdfLink: mirmiDoc
  }
];

const Research = () => {
  return <Section sectionTitle="Research" data={experiences} styleOverrides={{ minHeight: '180px' }} />;
};

export default Research;
