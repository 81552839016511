import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {

  apiKey: "AIzaSyCT8ACeq2e-SoxVOebp406Th87i1yi7Kgs",

  authDomain: "lgs-personal-website.firebaseapp.com",

  projectId: "lgs-personal-website",

  storageBucket: "lgs-personal-website.appspot.com",

  messagingSenderId: "820612374064",

  appId: "1:820612374064:web:90b39b04af20d088ecd7f5",

  measurementId: "G-CN3Q6MVKF2"

};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
