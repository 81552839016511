import React from 'react';
import { Typography, Container, Link } from '@mui/material';

function DataPolicy() {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Data Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Last updated: December 18, 2023
      </Typography>
      <Typography variant="body1" paragraph>
        This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
      </Typography>
      <Typography variant="body1" paragraph>
        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Free Privacy Policy Generator.
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about this Privacy Policy, You can contact us by email: <Link href="mailto:luis.gasparschroeder@gmail.com">luis.gasparschroeder@gmail.com</Link>
      </Typography>
    </Container>
  );
}

export default DataPolicy;
