import React from 'react';
import { Box, MenuItem, Menu, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as ScrollLink } from 'react-scroll';

function BurgerMenu({ open, onClose }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    onClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    if (onClose) onClose();
  };

  const navLinks = [
    { label: 'Home', to: 'home' },
    { label: 'Education', to: 'education' },
    { label: 'Research', to: 'research' },
    { label: 'Work Experience', to: 'experience' },
  ];

  return (
    <Box> 
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl) && open} // Control visibility based on both the anchor and the open prop
        onClose={handleMenuClose}
      >
        {navLinks.map((navLink) => (
          <MenuItem key={navLink.to} onClick={handleMenuClose}>
            <ScrollLink to={navLink.to} smooth={true} style={{ textDecoration: 'none', color: 'inherit' }}>
              {navLink.label}
            </ScrollLink>
          </MenuItem>
        ))}
      </Menu>
    </Box>  
  );
}

export default BurgerMenu;
