import React, { useState } from 'react';
import { useTheme, useMediaQuery, AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import BurgerMenu from './burgermenu';
import { Link as ScrollLink } from 'react-scroll';

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const linkStyle = {
    cursor: 'pointer',
    color: 'inherit', 
    textDecoration: 'none', 
    marginRight: '10px', 
    '&:hover': {
      textDecoration: 'underline', 
    },
  };

  return (
    <AppBar position="fixed" style={{ backgroundColor: '#1E1E1E' }}>
      <Toolbar>
        <Box display="flex" alignItems="center">
          {isMobile && (
            <BurgerMenu open={drawerOpen} onClose={() => setDrawerOpen(!drawerOpen)} />
          )}
          <Typography variant="h6" noWrap component="div" style={{ fontWeight: "bolder" }}>
            Luis Gaspar Schroeder
          </Typography>
          {!isMobile && (
            <Box display="flex" component="nav" style={{ marginLeft: '20px' }}>
              <ScrollLink to="home" smooth={true} style={linkStyle}>Home</ScrollLink>
              <ScrollLink to="education" smooth={true} style={linkStyle}>Education</ScrollLink>
              <ScrollLink to="research" smooth={true} style={linkStyle}>Research</ScrollLink>
              <ScrollLink to="experience" smooth={true} style={linkStyle}>Work Experience</ScrollLink>
            </Box>
          )}
        </Box>
        {!isMobile && <Box flexGrow={1} />}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
