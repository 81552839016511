import React from 'react';

function Imprint() {
  return (
    <div>
      <h1>Imprint</h1>
      {/* Add your imprint content here */}
    </div>
  );
}

export default Imprint;
