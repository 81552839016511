import React from 'react';
import Section from '../common/section'

import tumLogo from "../../images/logos/tum.jpg";

const experiences = [
  {
    name: "University of California, Berkeley",
    title: "Visiting Student",
    period: "08/2024 - 12/2024",
    location: "Berkeley, United States",
    description: "I am focusing on Databases and Distributed Systems.",
    imageUrl: tumLogo 
  },
  {
    name: "Technical University of Munich",
    title: "M.Sc. Computer Science",
    period: "04/2024 - 03/2026",
    location: "Munich, Germany",
    description: "I am majoring in Databases, with minors in Distributed Systems and High Performance Computing.",
    imageUrl: tumLogo 
  },
  {
    name: "Technical University of Munich",
    title: "B.Sc. Computer Science",
    period: "03/2021 - 03/2024",
    location: "Munich, Germany",
    description: "I developed an algorithm in x86 Assembly that computes large Fibonacci numbers using matrix multiplication optimization in my second semester. I taught students how to program iOS mobile apps using Swift as a Teaching iOS tutor.",
    imageUrl: tumLogo 
  }
];

const Education = () => {
  return <Section sectionTitle="Education" data={experiences} styleOverrides={{ minHeight: '170px' }} />;
};

export default Education;
